import {axios_instance as axios} from '@/axios-config.js';
import router from '@/router/index';
import { EventBus } from '@/main';

export default {
  namespaced: true,

  state: {
    totalItems: null,
    knowledgeBaseCategoryList: null,
    hasError: false,
    ticketItem: null,
    anwserReply: ''
  },

  getters: {
    
  },

  mutations: {
    SET_TOTAL_ITEMS(state, payload) {
        state.totalItems = payload.data['hydra:totalItems']
    },
    SET_CATEGORIES(state, payload) {
        state.knowledgeBaseCategoryList = payload.data['hydra:member'];
    },
    SET_ERROR(state, payload) {
        state.hasError = payload;
    },
    SET_TICKET_ITEM(state, payload = null) {
        state.ticketItem = payload ? payload.data : payload;
    },
    UPDATE_ANSWER_REPLY(state, payload) {
        state.anwserReply = payload;
    }
  },

  actions: {
    getKnowledgeBaseCategories({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('app/knowledge_base_categories?context=knowledgeBaseCategory')
            .then(response => {
              commit('SET_TOTAL_ITEMS', response);
              commit('SET_CATEGORIES', response);
              resolve();
            })
            .catch(error => {
              commit('SET_ERROR', true);
              reject(error);
            });
        })
    }, 
    getTicket({ commit }) {
        axios
        .get('app/tickets/' + router.history.current.params.id + '?context=ticket')
        .then(response => {
            commit('SET_TICKET_ITEM', response)
        })
        .catch(error => {
            commit('SET_ERROR', true);
            console.error('Error:', error);
        });
    },
    sendTicket(context, payload) {
        return new Promise((resolve, reject)=>{
            axios
              .post('app/tickets?context=ticket', {
                title: payload.formTitle,
                message: payload.formMessage,
                category: '/api/app/knowledge_base_categories/' + payload.formCategory + '?context=knowledgeBaseCategory',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then(() => {
                EventBus.$emit('triggerAlert', { message: 'Tworzenie zgłoszenia zakończone powodzeniem' });
                resolve();
              })
              .catch(error => {
                EventBus.$emit('triggerAlert', { message: `Błąd! Nie udało się wysłać zgłoszenia`, triggerManualError: true });
                reject(error);
              });
        })
    },
    onSendAnswer ({ commit, state }) {
        if (state.anwserReply !== '') {
            return new Promise((resolve, reject) => {
                axios
                .post('app/tickets/' + router.history.current.params.id + '/answer?context=ticket', {
                    answer: state.anwserReply,
                    headers: {
                    'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    commit('UPDATE_ANSWER_REPLY', null);
                    commit('SET_TICKET_ITEM', response)
                    EventBus.$emit('triggerAlert', { message: 'Odpowiedź została wysłana pomyślnie', type: 'anwserReply' });
                    resolve();
                })
                .catch(error => {
                    EventBus.$emit('triggerAlert', { message: `Błąd! Nie udało się wysłać wiadomości`, triggerManualError: true });
                    reject(error);
                });
            })
        }
    },
    onCloseTicket({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('app/tickets/' + router.history.current.params.id + '/close?context=ticket')
            .then(response => {
                commit('SET_TICKET_ITEM', response);
                EventBus.$emit('triggerAlert', { message: 'Zamknięcie zgłoszenia zakończone sukcesem' });
                resolve();
            })
            .catch(error => {
                EventBus.$emit('triggerAlert', { message: `Błąd! Nie udało się zamknąć zgłoszenia`, triggerManualError: true });
                reject(error);
            });
        })
    }
  }
}
