import {axios_instance as axios} from '@/axios-config.js';
import router from '@/router/index';

export default {
  namespaced: true,

  state: {
    knowledgeBaseList: null,
    totalItems: null,
    knowledgeBaseItem: null
  },

  mutations: {
    SET_BASE_LIST(state, payload) {
        state.knowledgeBaseList = payload.data['hydra:member']
    },
    SET_TOTAL_ITEMS(state, payload) {
        state.totalItems = payload.data['hydra:totalItems']
    },
    SET_BASE_ITEM(state, payload) {
        state.knowledgeBaseItem = payload.data
    }
  },

  actions: {
    setKnowledgeBaseList({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('app/knowledge_base_contents?context=knowledgeBaseContent')
            .then(response => {
                commit('SET_BASE_LIST', response)
                commit('SET_TOTAL_ITEMS', response)
                resolve();
            })
            .catch(error => {
                reject(error)
            });
        })
    },
    getKnowledgeBaseContents({ commit }) {
        return new Promise((resolve, reject)=>{
            axios
            .get('app/knowledge_base_contents/' + router.history.current.params.id + '?context=knowledgeBaseContent')
            .then(response => {
                commit('SET_BASE_ITEM', response)
                resolve()
            })
            .catch(error => {
                reject(error)
            });
        })
    }
  }
}
