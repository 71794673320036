<template>
  <div class="select-department">
    <template v-if='departments !== null'>
      <select v-model='selectedDepartment'>
        <option disabled value="">Wybierz oddział</option>
        <option :value='department' :key='department.uuid' v-for='department in departments'>{{department.name}}</option>
      </select>
    </template>
    <template v-else>
      <div class="loader loader-select"></div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'navigation',
  computed: {
    ...mapState(['departments']),

    selectedDepartment: {
      get() {
        return this.$store.state.selectedDepartment
      },
      set(value) {
        this.$store.commit('SET_SELECTED_DEPARTMENT', value)
      }
    }
  },
}
</script>

<style lang="scss">
  .transparentSelect {
    margin-right:0 !important;
    &>select {
      background: url('../assets/images/icons/actions-general/arrow-down-black.png') transparent right .5rem center no-repeat !important;
      color: black !important;
    }
  }
</style>
