<template>
  <form class="right-block__form  form--columned form">
        <input v-model='newPassword1' type="password"  required="required" placeholder="Nowe hasło" maxlength="255" class="form__input form__input"/>
        <input v-model='newPassword2' type="password"   required="required" placeholder="Nowe hasło" maxlength="255" class="form__input form__input"/>
        <label v-if='error.status' class="form__error" :style='{color: error.color}' v-html='error.message'></label>
        <div>
            <button @click.prevent='sendNewPassword' id="recovery_step1_Odzyskaj hasło" class="form__button">Zmień hasło</button>
        </div>
    </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed: {
        ...mapState('recovery', ['error']),
        newPassword1: {
            get () {
                return this.$store.state.newPassword1
            },
            set (value) {
                this.$store.commit('recovery/UPDATE_NEW_PASSWORD_1', value)
            }
        },
        newPassword2: {
            get () {
                return this.$store.state.newPassword1
            },
            set (value) {
                this.$store.commit('recovery/UPDATE_NEW_PASSWORD_2', value)
            }
        }
    },
    methods: mapActions('recovery', ['sendNewPassword'])
}
</script>

<style>

</style>