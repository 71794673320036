//    import {baseUrl} from '../../main';
// let tableNameDiets = 'user_active_diets';
// let contextDiets = '?context=dietGrid';
// let contextMenu = "?context=menuGrid";

const tableName = 'tickets';
const context = '?context=ticketGrid';
const tableNameMenu = 'tickets';

const confticket = {
  urlAPI: 'app/' + tableName + context,
  tableName: 'AS_' + tableName,
  context: context,
  exportToExcel: false,
  addNewItemButton: true,
  addNewItemButtonName: 'Dodaj zgłoszenie',
  addNewItemButtonRoute: tableNameMenu + '/add',
  stateManager: {
    saveFilter: false,
    saveSorting: false,
    saveClickedRow: false,
  },
  columns: {
    uuid: {
      areShown: false,
      label: 'UUID',
    },
    textNumber: {
      areShown: true,
      label: 'Numer',
    },
    'category.title': {
      areShown: true,
      label: 'Kategoria',
    },
    title: {
      areShown: true,
      label: 'Tytuł',
    },
    'createdBy.fullName': {
      areShown: true,
      label: 'Autor',
    },
    createdAt: {
      areShown: true,
      label: 'Utworzono',
      fun: function(data) {
        return data.createdAt.substring(0, 10);
      },
    },
    isClosed: {
      areShown: true,
      label: 'Status',
      options: ['Otwarty', 'Zamknięty', 'Przekazany do eksperta'],
      fun: function(data) {
        let output = '';
        if (data.isClosed === false && data.isRedirected === false) {
          output = `<span class="text-success">${this.options[0]}</span>`;
        } else if (data.isClosed === false && data.isRedirected === true) {
          output = `<span class="text-info">${this.options[2]}</span>`;
        } else {
          output = `<span class="text-warning">${this.options[1]}</span>`;
        }
        return output;
      },
    },
    actions: {
      areShown: true,
      label: 'Akcje',
    },
  },
  actions: [
    {
      type: 'show',
      text: 'Pokaż',
      url: tableNameMenu + '/show/{uuid}',
    },
  ],
};

export default confticket;
