<template>
    <div class="container-fluid" style='margin-top:30px'>
        <vue-progress-bar></vue-progress-bar>
        <template v-if="nPlanData.uuid && serverTime !== null && computedPositions.length !== 0">
            <div class="row">
                <div v-if="nPlanData.status" class="col-12">
                    <div class="alert alert-warning alert--grouped mb-4">
                        <i class="fe fe-alert-triangle mr-2"></i>
                        Plan został już zatwierdzony, więc możliwy jest tylko jego podgląd lub korekta.
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="card-deck">

                        <div class="card card-content shadow-sm">
                            <div class="card-header shadow border-primary">
                                <div class="d-flex align-content-between border-bottom mb-1 pb-0">
                                    <p class="h3 text-muted mb-1">Klient</p>
                                    <div class="card-options ml-auto align-self-center">

                                    </div>
                                </div>
                                <span class="card-title">{{ nPlanData.companyDepartment.company.name }}</span>
                            </div>
                            <div class="card-body">
                                <p class="small border-bottom pb-1 mb-1">
                                    <span class="text-muted">
                                        <i class="fe fe-tag pr-2"></i>Oddział:
                                    </span>
                                    <template v-if="nPlanData.companyDepartment">
                                        {{ nPlanData.companyDepartment.name }}
                                    </template>
                                </p>
                                <p class="small mb-0"><span class="text-muted"><i class="fe fe-map-pin pr-2"></i>Adres: </span>
                                    <template v-if="nPlanData.companyDepartment">
                                        {{ nPlanData.companyDepartment.address }} {{ nPlanData.companyDepartment.postalCode }} {{ nPlanData.companyDepartment.city }}
                                    </template>
                                    <template v-else>
                                        {{ nPlanData.client.company.address }} {{ nPlanData.client.company.postalCode }} {{ nPlanData.client.company.city }}
                                    </template>
                                </p>
                            </div>
                        </div><!-- /card -->

                        <!-- breakpoint separator -->
                        <div class="w-100 d-lg-none mt-2"></div>

                        <div class="card card-content shadow-sm">
                            <div class="card-header shadow border-warning">
                                <div class="d-flex align-content-between border-bottom mb-1 pb-0">
                                    <p class="h3 text-muted mb-1">Szczegóły planu</p>
                                    <div class="card-options ml-auto align-self-center">
                                        <span
                                            v-if="nPlanData.status === true"
                                            class="badge badge-success p-2"
                                        >
                                                <i class="fe fe-lock mr-2"></i>Zatwierdzony
                                        </span>
                                        <span
                                            v-else
                                            class="badge badge-primary p-2"
                                        >
                                            <i class="fe fe-unlock mr-2"></i>Otwarty
                                        </span>
                                    </div>
                                </div>
                                <span class="card-title">
                                    <span class="text-muted">
                                        <i class="fe fe-user pr-2"></i>Sporządził:
                                    </span> {{ nPlanData.createdBy.fullName }}<br/>
                                    <span class="text-muted">
                                        <i class="fe fe-calendar pr-2"></i>Dnia:
                                    </span> {{ nPlanData.createdAt }}
                                </span>
                            </div>
                            <div class="card-body">
                                <p class="small border-bottom pb-1 mb-1">
                                    <span class="text-muted">
                                        <i class="fe fe-activity pr-2"></i>Status:
                                    </span>
                                    {{ nPlanData.status === true ? 'Zatwierdzony automatycznie' : 'Otwarty' }}
                                </p>
                                <template v-if="canEditDescriptionTextArea && granted_edit">
                                    <textarea
                                        @change="updateDocument({'notes': nPlanData.notes})"
                                        class="form__textarea w-100"
                                        name="notes"
                                        :disabled='isBeingUpdated'
                                        placeholder="Uwagi"
                                        rows="2"
                                        v-model="nPlanData.notes">
                                    </textarea>
                                </template>
                                <template v-else>
                                    <p class="small mb-0">
                                        <span class="text-muted">
                                            <i class="fe fe-message-square pr-2"></i>Uwagi:
                                        </span>
                                        {{ nPlanData.notes }}
                                    </p>
                                </template>
                            </div>
                        </div><!-- /card -->

                        <!-- breakpoint separator -->
                        <div class="w-100 d-lg-none mt-2"></div>

                        <div class="card card-content card-content--extras shadow-sm">
                            <div class="card-header">
                                <div class="card-icon bg-primary shadow">
                                    <i class="fe fe-calendar h2"></i>
                                </div>
                                <p class="card-category text-right">Plan na dzień</p>
                                <h3 class="card-title">{{ nPlanData.planDate.substring(0,10) }}</h3>
                            </div>
                            <div class="card-body py-2 d-flex align-items-center">
                                <i class="fe fe-clock text-warning"></i>
                                <span class="text-warning ml-2">
                                    Termin złożenia planu mija <strong>{{ nPlanData.contractDetails.dayToSendNutritionPlan }}</strong>
                                </span>
                            </div>
                        </div><!-- /card -->
                    </div><!-- /card deck -->
                </div><!-- /col-12 -->
            </div><!-- /row -->

            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col">
                            <h5 class="mt-4">Lista</h5>
                        </div>
                        <div class="col text-right">
                            <div class="custom-control custom-switch mt-4">
                                <input type="checkbox" class="custom-control-input" v-model="showDietsOnlyForDepartment" id="showDietsOnlyForDepartment">
                                <label class="custom-control-label" for="showDietsOnlyForDepartment">Pokaż tylko diety dla oddziału</label>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <template v-if="nPlanData.contractDetails === null">
                            <p class="text-center font-weight-bold">Brak aktywnej umowy</p>
                        </template>
                        <template v-else-if="nPlanData.contractDetails">
                            <table class="table table--grid table-striped table-bordered table-sm">
                                <thead class="thead-light">
                                    <tr class="d-flex border-bottom">
                                        <th class="col-3 align-bottom">Stan żywionych</th>
                                        <th class="col border-left text-center" :key='index' v-for="(cMeal, index) in nPlanData.contractDetails.contractMeals">
                                            <div class="row mx-0 text-muted">
                                                <div class="col">{{ totalMealsInColumn(cMeal) }}</div>
                                                <div class="col" v-if="!canEditPlanValues || !granted_edit">{{ totalCorrectionInColumn(cMeal) }}</div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="d-flex">
                                        <th class="col-3 align-bottom">Nazwa diety</th>
                                        <th class="col" :key='index' v-for="(cMeal, index) in nPlanData.contractDetails.contractMeals">
                                            {{ cMeal.meal.name }}<br/>
                                            <span class="small">Korekta do godz.: {{ cMeal.timeToCorrect ? cMeal.timeToCorrect.substring(11,16) : null}}</span>
                                            <div v-if="!canEditPlanValues || !granted_edit" class="row mx-0">
                                                <div class="col pl-0">Plan</div>
                                                <div class="col pr-0">Korekta</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="cDiet in filteredNourishedPlanDiets">
                                        <tr class="d-flex" :key='cDiet.uuid'>
                                            <td class="col-3">
                                                <div style='height: 100%; display: flex; align-items: center;'>
                                                    <span v-if='cDiet.diet.color' style='min-width:10px; margin-right:5px; display:inline-block; width:10px;height:10px; border-radius:50%' :style='{backgroundColor: cDiet.diet.color}'></span>
                                                    {{ cDiet.diet.name }} {{ cDiet.diet.isSpecial ? '(Specjalna)' : null }}
                                                </div>
                                            </td>
                                            <template v-if="cDiet.activeMenu.uuid === undefined">
                                                <td class="text-center font-italic text-warning col"
                                                    :colspan="nPlanData.contractDetails.contractMeals.length">
                                                    Brak aktywnego menu dla tej diety.
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td
                                                    v-for="(cMeal, index) in nPlanData.contractDetails.contractMeals"
                                                    :key='index'
                                                    class="col">

                                                    <template v-if="!cDiet.hasOwnProperty('nourishedPlanPositions')">
                                                        <div class="w-100 align-bottom small font-italic"> </div>
                                                    </template>
                                                    <template v-else-if="computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid] !== undefined ">
                                                        <template v-if="canEditPlanValues && granted_edit">
                                                            <TableInput
                                                                :correction='false'
                                                                :peopleNumber ='computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber'
                                                                :uuid='cMeal.meal.uuid'
                                                                :computedPositions='computedPositions'
                                                                :diet='cDiet'
                                                                >
                                                            </TableInput>
                                                        </template>
                                                        <template v-else>
                                                            <div class="row mx-0">
                                                                <div class="col pl-0">
                                                                    <div class="w-100 no-input">
                                                                        {{ computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber ? computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber : "-"}}
                                                                        &nbsp;
                                                                    </div>
                                                                </div>
                                                                <div class="col pr-0">
                                                                    <template  v-if="new Date(cMeal.dayToCorrectNutritionPlan) > serverTime && granted_edit">
                                                                        <TableInput
                                                                            :correction='true'
                                                                            :correctionValue='computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].correction'
                                                                            :uuid='cMeal.meal.uuid'
                                                                            :computedPositions='computedPositions'
                                                                            :diet='cDiet'>
                                                                        </TableInput>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="w-100 no-input">
                                                                            {{ computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].correction ? computedPositions[cDiet.uuid].nourishedPlanPositions[cMeal.meal.uuid].correction : '-'}}
                                                                            &nbsp;
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <div class="w-100 align-bottom small font-italic"> </div>
                                                    </template>
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </template>
                        <template v-else>
                            <div class="loader"></div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <p class="small mb-0">
                        <span class="text-muted">Data utworzenia:</span>
                        {{ nPlanData.createdAt }}
                    </p>
                    <p class="small">
                        <span class="text-muted">Data modyfikacji:</span>
                        {{ nPlanData.updatedAt }}
                    </p>
                </div>
                <div class="col-md-6 text-right">
                    <button
                        v-if="!nPlanData.status && granted_edit"
                        @click="updateDocument"
                        class="basic-info__add-btn" >
                            Zakończ
                    </button>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="loader"></div>
        </template>

        <div v-if="isBeingUpdated" class='loader-container'>
            <div  class="loader loader--fixed"></div>
        </div>
    </div><!-- /container-fluid -->
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css';
import { EventBus } from '@/main';
import TableInput from '../TableInput.vue';
import { mapActions, mapState } from 'vuex';
export default {
  props: ['createForFewDays'],
  components: {
    TableInput,
  },
  data: function () {
    return {
      granted_edit: true,
      showDietsOnlyForDepartment: true,
    };
  },
  created: function () {
    this.getPlanData(this.$route.params.id);

    EventBus.$on('newPositionValue', ({ response, diet, uuid }) => {
      this.computedPositions[diet.uuid].nourishedPlanPositions[uuid] = response.data;
    });
  },
  computed: {
    ...mapState('plans',['nPlanData', 'serverTime', 'isBeingUpdated']),
    canEditPlanValues: function () {
      if (this.nPlanData !== null && this.serverTime !== null) {
        const maxPlanDate = new Date(this.nPlanData.contractDetails.dayToSendNutritionPlan);
        return this.nPlanData.status === true ? false : maxPlanDate > this.serverTime;
      } else {
        return null;
      }
    },
    canEditDescriptionTextArea() {
        let meals = this.nPlanData.contractDetails.contractMeals;
        let maxCorrectionDate = null;
        for (let i = 0; i < meals.length ; i++) {
            let correctDate = new Date(meals[i].dayToCorrectNutritionPlan);
            maxCorrectionDate = correctDate > maxCorrectionDate ? correctDate : maxCorrectionDate;
        }
        return maxCorrectionDate > this.serverTime;
    },
    generatedDays: function() {
      if (this.nPlanData !== null) {
        const planDate = new Date(this.nPlanData.planDate);
        const lastPossibleDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7);
        const howManyDays = (lastPossibleDate - planDate) / 1000 / 3600 / 24;
        return howManyDays > 7 ? 7 : howManyDays;
      } else {
        return null;
      }
    },
    computedPositions: function () {
      let arrayReduced = [];

      if (this.nPlanData) {
        arrayReduced = Array.from(this.nPlanData.nourishedPlanDiets).reduce((obj, item) => {
          obj[item.uuid] = item;
          if (item.nourishedPlanPositions) {
            item.nourishedPlanPositions = item.nourishedPlanPositions.reduce((posObj, posItem) => {
              posObj[posItem.menuMeal.dMeal.uuid] = posItem;
              return posObj;
            }, {});
            // item.nourishedPlanPositions = item.nourishedPlanPositions.reduce((posObj, posItem) => {
            //   posObj[posItem.menuMeal.dMeal.uuid] = posItem;
            //   return posObj;
            // }, {});
          }
          return obj;
        }, {});
      }
      return arrayReduced;
    },
    filteredNourishedPlanDiets() {
        if (this.showDietsOnlyForDepartment && this.nPlanData.companyDepartment.assignedDepartmentDiets) {
            return this.nPlanData.nourishedPlanDiets.filter(pd => this.nPlanData.companyDepartment.assignedDepartmentDiets.some(dd => pd.diet.uuid === dd.uuid));
        }
        return this.nPlanData.nourishedPlanDiets;
    }
  },
  methods: {
    ...mapActions('plans', ['getPlanData']),
    updateDocument() {
        this.$Progress.start()
        this.$store.dispatch('plans/updateDocument')
        .then(()=>this.$Progress.finish())
        .catch(error=>{
            this.$Progress.fail()
            console.error('Error:', error);
        })
    },
    totalMealsInColumn(cMeal) {
        let totalMeals = 0;
        Object.keys(this.nPlanData.nourishedPlanDiets).forEach(key => {
            if (this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid] !== undefined ) {
                if (this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber != null) {
                    totalMeals = totalMeals + this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber;
                }
            }
        });
        return totalMeals
    },
    totalCorrectionInColumn(cMeal) {
        let totalCorrections = 0;
        Object.keys(this.nPlanData.nourishedPlanDiets).forEach(key => {
            if (this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid] !== undefined ) {
                if (this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].correction != null) {
                    totalCorrections = totalCorrections + this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].correction;
                }
                if (this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber != null &&
                    this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].correction == null) {
                    totalCorrections = totalCorrections + this.computedPositions[this.nPlanData.nourishedPlanDiets[key].uuid].nourishedPlanPositions[cMeal.meal.uuid].peopleNumber;
                }
            }
        });
        return totalCorrections
    }
  },
  watch: {
    '$route'() {
      this.getPlanData(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/variables';
@import '../../assets/css/scoped-forms';

.no-input {
    background-color: #ebebeb;
    border-radius: 5px;
    border: 1px solid $form-input-border-col;
    padding: 3px 0 3px 10px;
}

input, textarea {
    background-color: white !important;
    // border-style: none;
    // color: inherit;
}
.form__select {
    appearance: initial;
    background-color: white !important;
    -webkit-appearance: menulist;
}
</style>
