import {axios_instance as axios} from '@/axios-config.js';
import router from '../router/index';

export default {
  namespaced: true,

  state: {
    error: {
        status: false,
        message: '',
        color: '#dc3545',
    },
    email: null,
    newPassword1: '',
    newPassword2: '',
  },

  mutations: {
    UPDATE_EMAIL: (state, payload) => {
        state.email = payload;
    },
    UPDATE_ERROR: (state, payload) => {
        let message = ``;
        if(typeof payload.error === 'object') {
            if (payload.error.response && payload.error.response.data.violations) {
                for (const violation of payload.error.response.data.violations) {
                    message += violation.message + `<br/>`;
                }
            } else if (payload.error.response && payload.error.response.data.violations == undefined && payload.error.response.data['hydra:description']) {
                message += payload.error.response.data['hydra:description'] + `<br/>`;
            } else if (payload.error.response && payload.error.response.data.violations == undefined && payload.error.response.data.details) {
                message += payload.error.response.data.details;
            }
            else if (payload.error.data && payload.error.data.message) {
                message += payload.error.data.message;
            }
            else {
                message += payload.error.message
            }
        }
        state.error = {
            status: payload.status,
            color: payload.color,
            message: typeof payload.error === 'object' ? message : payload.error
        }
        payload.redirect ? router.push({
            name: 'login',
        }) : null;
    },
    UPDATE_NEW_PASSWORD_1: (state, payload) => {
        state.newPassword1 = payload;
    },
    UPDATE_NEW_PASSWORD_2: (state, payload) => {
        state.newPassword2 = payload;
    },
  },

  actions: {
    sendEmail({dispatch, state, commit}) {
        dispatch('validateEmail').then(validationSuccess => {
            if (validationSuccess) {
              axios.post(`app/recovery_password`, {
                email: state.email,
              }).then(response => {
                commit('UPDATE_ERROR', {
                    status: true,
                    color: '#339648',
                    error: response.data.message
                })
              }).catch(error => {
                commit('UPDATE_ERROR', {
                    status: true,
                    color: '#dc3545',
                    error,
                })
              });
            } else {
                commit('UPDATE_ERROR', {
                    status: true,
                    color: '#dc3545',
                    error: 'Wpisz właściwy adres email'
                })
            }
        })
    },
    sendNewPassword({ commit, state }) {
        if ( state.newPassword1 === state.newPassword2 ) {
            axios.put(`app/recovery_password/step2/${router.history.current.params.user_uuid}/token/${router.history.current.params.user_token}?context=user`, { 'plainPassword': state.newPassword1 })
            .then(response => {
                commit('UPDATE_ERROR', {
                    status: true,
                    color: '#339648',
                    error: response,
                    redirect: true
                })
            })
            .catch(error => {
                commit('UPDATE_ERROR', {
                    status: true,
                    color: '#dc3545',
                    error,
                })
            });
        } else {
            commit('UPDATE_ERROR', {
                status: true,
                color: '#dc3545',
                error: 'Sprawdź poprawność wpisanych haseł'
            })
        }
    },
    validateEmail({ state }) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(state.email).toLowerCase());
    },
  }
}
