<template>
    <div>Version {{applicationVersion}}</div>
</template>

<script>
import { version } from "../package.json"
export default {
  data() {
    return {
      applicationVersion: version,
    };
  },
};
</script>

<style scoped>
div {
    padding:10px 20px;
    text-align: right;
}
</style>
