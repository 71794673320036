import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth"
import recovery from './recovery'
import plans from './plans'
import grid from './grid'
import menu from './menu'
import tickets from './tickets'
import knowledgeBase from './knowledgeBase'
import elearning from './elearning'
import {axios_instance as axios} from '@/axios-config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    departments: null,
    selectedDepartment: null
  },

  mutations: {
    SET_DEPARTMENTS(store, data) {
      store.departments = data;
    },
    SET_SELECTED_DEPARTMENT(store, payload) {
      store.selectedDepartment = payload
    }
  },

  actions: {
    async getFromApiDepartments({ commit }) {
      const response = await axios.get('app/company_departments/user_departments?context=companyDepartmentGrid');
      commit('SET_DEPARTMENTS', response.data['hydra:member'])
      commit('SET_SELECTED_DEPARTMENT', response.data['hydra:member'][0])
    }
  },

  modules: {
    auth,
    recovery,
    plans,
    grid,
    menu,
    tickets,
    knowledgeBase,
    elearning
  }
})
