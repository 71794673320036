<template>
    <router-view/>
</template>

<script>
  // @ is an alias to /src
import { mapActions } from 'vuex';
export default {
  created() {
    !this.$route.path.includes('login') ? this.getFromApiDepartments() : null;
  },
  methods: mapActions(['getFromApiDepartments'])
}
</script>
