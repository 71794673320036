import conf from "../views/Table/menu-conf.js";
export default {
  namespaced: true,
  state: {
    config: conf,
  },
  getters: {},
  mutations: {},
  actions: {},
};
