<template>
  <td>
      <div style="float:left; display: flex; align-items: center; justify-content: space-between; width: 100%;">
        <input
          type="number"
          min="0"
          v-model.number='ownQuantity'
          @keyup.enter="sendChangedData"
          @blur="sendChangedData"
          class="w-100"
          v-bind:class="{ 'form__input--loader' : isBeingUpdated }"
        >
        <!-- class="w-100 form__input" -->
      </div>
  </td>
</template>

<script>
import { EventBus } from '../main';
import { mapActions, mapState } from 'vuex';
export default {
  props: [
    'peopleNumber',
    'uuid',
    'computedPositions',
    'diet',
    'correction',
    'correctionValue',
  ],
  data() {
    return {
      ownQuantity: 0,
    };
  },
  created() {
    this.ownQuantity = [this.releaseQuantity, this.peopleNumber, this.correctionValue].find(item => item !== undefined);
  },
  computed: mapState('plans',['isBeingUpdated']),
  methods: {
    ...mapActions('plans', ['changePlanPosition']),
    sendChangedData() {
      let comparedValues;

      if (this.correction == false) {
        comparedValues = this.peopleNumber !== undefined && this.peopleNumber !== this.ownQuantity;
      } else {
        comparedValues = this.correctionValue !== undefined && this.correctionValue !== this.ownQuantity;
      }

      if (comparedValues) {
        if (this.ownQuantity == null || this.ownQuantity == undefined || typeof this.ownQuantity === 'string') {
          EventBus.$emit('triggerAlert', { message: 'Podana wartość jest błędna', triggerManualError: true, type: 'inputError' });  // Aktualizowana wartość nie może być pusta
          this.ownQuantity = [this.releaseQuantity, this.peopleNumber, this.correctionValue].find(item => item !== undefined);
          return false;
        }

        const dataForSending = this.correction ? { correction: parseInt(this.ownQuantity) } : { peopleNumber: parseInt(this.ownQuantity) };
        const posId = this.computedPositions[this.diet.uuid].nourishedPlanPositions[this.uuid].uuid;
        this.$Progress.start()
        this.changePlanPosition({dataForSending,posId})
        .then((data)=>{
          this.$Progress.finish()
          EventBus.$emit('newPositionValue', { response: data, diet: this.diet, uuid: this.uuid });
        })
        .catch(error=> {
          this.$Progress.fail()
          console.error('Error:', error)
        })
      }
    },
  },
  watch: {
    peopleNumber() {
      this.ownQuantity = [this.releaseQuantity, this.peopleNumber, this.correctionValue].find(item => item !== undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
td{
  // background-color: #eeeeee;
  border:none !important;
}
input {
  // cursor: pointer;
  width: 100%;
  color: $tbody-txt-col;
  border: 1px solid #c4c0d8;
  padding: 2px 5px;
  border-radius: 3px;
  outline: none;
  background-color:white;
  &:hover {
    border:1px solid black;
  }
}
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
</style>
