import { axios_instance as axios } from "@/axios-config.js";
import { EventBus } from "@/main";
export default {
  namespaced: true,

  state: {
    plans: null,
    nPlanData: [],
    serverTime: null,
    isBeingUpdated: false,
    incoming_info: false,
  },

  getters: {},

  mutations: {
    SET_PLANS(state, payload) {
      state.plans = payload;
    },
    ADD_PLAN(state, payload) {
      state.plans.push(payload);
    },
    ADD_PLANS(state, payload) {
      state.plans = [...state.plans, ...payload];
    },
    SET_PLAN_DATA(state, payload) {
      state.nPlanData = payload;
    },
    SET_SERVER_TIME(state, payload) {
      payload.type === "reset"
        ? (state.serverTime = null)
        : (state.serverTime = new Date(payload));
    },
    UPDATE_LOADING_STATUS(state, payload) {
      state.isBeingUpdated = payload;
    },
    SET_INCOMING_INFO(state, payload) {
      state.incoming_info = payload;
    },
  },

  actions: {
    getPlans({ commit, rootState, state }, type) {
      if (rootState.selectedDepartment && rootState.selectedDepartment.uuid) {
        if (type !== "loop") {
          commit("SET_PLAN_DATA", null);
          commit("SET_PLANS", null);
        }
        axios
          .get(
            `app/nourished_plans?companyDepartment.uuid=${rootState.selectedDepartment.uuid}&context=nourishedPlanGrid`
          )
          .then((response) => {
            let areEqual =
              JSON.stringify(response.data["hydra:member"]) ===
              JSON.stringify(state.plans);
            if (
              type === "loop" &&
              (state.plans.length !== response.data["hydra:member"].length ||
                !areEqual)
            ) {
              commit("SET_INCOMING_INFO", true);
            }
            commit("SET_PLANS", response.data["hydra:member"]);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    getServerTime({ commit }) {
      axios
        .get("/app/server_time")
        .then((response) => {
          commit("SET_SERVER_TIME", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    getPlanData({ commit, dispatch }, planId) {
      commit("SET_PLAN_DATA", []);
      commit("SET_SERVER_TIME", {
        type: "reset",
      });

      dispatch("getServerTime");
      axios
        .get("app/nourished_plans/" + planId + "?context=nourishedPlan")
        .then((response) => {
          commit("SET_PLAN_DATA", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    createPlan({ rootState, commit }, payload) {
      const url =
        payload.fillWithLastPlanData === true
          ? `app/nourished_plans?context=nourishedPlan&fillLastPlanData=true`
          : `app/nourished_plans?context=nourishedPlan`;

      return new Promise((resolve, reject) => {
        axios
          .post(url, {
            companyDepartment:
              "/api/company_departments/" + rootState.selectedDepartment.uuid,
            planDate: payload.planDate,
          })
          .then((response) => {
            commit("ADD_PLAN", response.data);
            EventBus.$emit("triggerAlert", {
              message: "Plan został utworzny pomyślnie",
              type: "createPlan",
            });
            resolve(response.data.uuid);
          })
          .catch((e) => {
            EventBus.$emit("triggerAlert", {
              message: e.response.data,
              type: "createPlan",
            });
            reject(e);
          });
      });
    },
    createPlans({ commit }, payload) {
      const url = `/app/nourished_plans/${payload.id}/create_for_few_days?context=nourishedPlan&howManyDays=${payload.daysNumber}`;
      axios
        .post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          commit("ADD_PLANS", response.data["hydra:member"]);
          EventBus.$emit("triggerAlert", {
            message: "Plany zostały utworzne pomyślnie",
            type: "createForFewDays",
          });
        })
        .catch((e) => {
          EventBus.$emit("triggerAlert", {
            message: e,
            type: "createForFewDays",
          });
          console.error(e);
        });
    },
    updateDocument({ commit, state }) {
      commit("UPDATE_LOADING_STATUS", true);
      return new Promise((resolve, reject) => {
        axios
          .put(
            "app/nourished_plans/" +
              state.nPlanData.uuid +
              "?context=nourishedPlan",
            { notes: state.nPlanData.notes },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            EventBus.$emit("triggerAlert", {
              message: "Plan został zaktualizowany poprawnie.",
              type: "updateDocument",
            });
            commit("SET_PLAN_DATA", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => commit("UPDATE_LOADING_STATUS", false));
      });
    },
    changePlanPosition({ commit }, payload) {
      const url = `app/nourished_plan_positions/${payload.posId}?context=nourishedPlanPosition`;
      commit("UPDATE_LOADING_STATUS", true);
      return new Promise((resolve, reject) => {
        axios
          .put(url, payload.dataForSending)
          .then((data) => {
            EventBus.$emit("triggerAlert", {
              message: data.data['percentDifferenceMessage'],
              type: "updatePlanPosition",
            });
            resolve(data);
            // this.ownQuantity = [this.releaseQuantity, this.peopleNumber, this.correctionValue].find(item=>item !== undefined);
          })
          .catch((error) => {
            reject(error);

            EventBus.$emit("triggerAlert", {
              message: error.response.data['hydra:description'],
              type: "updatePlanPosition",
              triggerManualError: true
            });


          })
          .finally(() => commit("UPDATE_LOADING_STATUS", false));
      });
    },
  },
};
