import Vue from 'vue';
import App from './App.vue';
import Maintenance from './Maintenance.vue';
import Version from './Version.vue';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vuetify/dist/vuetify.min.css';
import VueProgressBar from 'vue-progressbar';

import router from './router';
import store from './store';
// import {axios_instance} from './axios-config';
import wysiwyg from 'vue-wysiwyg';
import Vuetify from 'vuetify';
import './assets/css/app.scss';

require('@/store/subscriber');

Vue.config.productionTip = false;
// Vue.prototype.$http = axios_instance;

const vuetifyOptions = {
  dark: true,
};
Vue.use(Vuetify);
Vue.use(wysiwyg, {});
Vue.use(VueProgressBar, {
  color: '#00baff',
  failedColor: 'red',
  height: '2px',
});

export const EventBus = new Vue();
export const MaintenanceMode = process.env.VUE_APP_MAINTENANCE_MODE;

console.log(MaintenanceMode);

if (MaintenanceMode === '0') {
  new Vue({
    router,
    store,
    vuetify: new Vuetify(vuetifyOptions),
    render: h => h(App)
  }).$mount('#app');
} else {
  new Vue({
    render: h => h(Maintenance)
  }).$mount('#app');
}

new Vue({
  render: h => h(Version),
}).$mount('#version');
// store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
// });


window.$ = $;
