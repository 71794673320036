
import axios from 'axios';
// import {EventBus} from './main';
import store from '@/store';
import router from '@/router';

export const baseUrl = process.env.VUE_APP_ROOT_API_BASEURL;

export const axios_instance = axios.create({
    baseURL: baseUrl + '/api',
});


axios_instance.interceptors.request.use(
  function(request) {
    return request;
  },
  function(error) {
    return Promise.reject(error);
  }
);
  
axios_instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if(error.response.data.code === 401) {
      store.commit('auth/SET_TOKEN');
      router.push({name: 'login'});
    }
    // if (
    //   error.response &&
    //   error.response.status === 401 &&
    //   error.config.url !== `${error.response.config.baseURL}/api/login_check` &&
    //   !error.config.url.includes('recovery_password')
    // ) {
    //   localStorage.removeItem('token');
    //   window.location.reload();
    //   return Promise.reject(error);
    // }
    // EventBus.$emit('triggerAlert', {
    //   message: error.response.data,
    //   type: 'responseError',
    // });
    return Promise.reject(error);
  }
);