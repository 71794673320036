import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login';
import Calendar from '../views/Calendar';
import MiniCalendar from '../views/MiniCalendar';
import Diet from '../views/Diet';
import conf from '../views/Table/menu-conf.js';
import confticket from '../views/Table/ticket-conf.js';
import Table from '../views/Table/Table.vue';
import Container from '../views/PasswordRecovery/Container.vue';
import Step1 from '../views/PasswordRecovery/Step1.vue';
import Step2 from '../views/PasswordRecovery/Step2.vue';
import SwitchUser from '../views/SwitchUser.vue';
import NourishedPlanManagement from '@/views/Nourishment/NourishedPlanManagement.vue'
import PageNotFound from '../views/PageNotFound.vue';
import store from '../store';
// import jwtDecode from 'jwt-decode'; ???

Vue.use(VueRouter);

const Elearning = resolve => {
  require.ensure(['../views/Elearning/Elearning.vue'], () => {
    resolve(require('../views/Elearning/Elearning.vue'));
  }, 'Elearning');
};
const ElearningShow = resolve => {
  require.ensure(['../views/Elearning/ElearningShow.vue'], () => {
    resolve(require('../views/Elearning/ElearningShow.vue'));
  }, 'ElearningShow');
};
const KnowledgeBase = resolve => {
  require.ensure(['../views/KnowledgeBase/KnowledgeBase.vue'], () => {
    resolve(require('../views/KnowledgeBase/KnowledgeBase.vue'));
  }, 'KnowledgeBase');
};
const KnowledgeBaseShow = resolve => {
  require.ensure(['../views/KnowledgeBase/KnowledgeBaseShow.vue'], () => {
    resolve(require('../views/KnowledgeBase/KnowledgeBaseShow.vue'));
  }, 'KnowledgeBaseShow');
};
const TicketAdd = resolve => {
  require.ensure(['../views/Tickets/TicketAdd.vue'], () => {
    resolve(require('../views/Tickets/TicketAdd.vue'));
  }, 'TicketAdd');
};
const TicketShow = resolve => {
  require.ensure(['../views/Tickets/TicketShow.vue'], () => {
    resolve(require('../views/Tickets/TicketShow.vue'));
  }, 'TicketShow');
};
const ReportNourished = resolve => {
  require.ensure(['../views/Reports/ReportNourished.vue'], () => {
    resolve(require('../views/Reports/ReportNourished.vue'));
  }, 'ReportNourished');
};
const ReportIncome = resolve => {
  require.ensure(['../views/Reports/ReportIncome.vue'], () => {
    resolve(require('../views/Reports/ReportIncome.vue'));
  }, 'ReportIncome');
};
const ReportNutritional = resolve => {
  require.ensure(['../views/Reports/ReportNutritional.vue'], () => {
    resolve(require('../views/Reports/ReportNutritional.vue'));
  }, 'ReportNutritional');
};

export const routes = [
  {
    path: '/',
    component: Main,
    name: 'main',
    children: [
      {
        path: '',
        name: 'calendar',
        component: Calendar,
        meta: { requiresAuth: true, fullName: 'Kalendarz', role: "ROLE_AS_NOURISHED_PLAN_LIST_SHOW"}
      },
      {
        path: 'diets',
        name: 'diets',
        component: Table,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'Jadłospisy', role: "ROLE_AS_DIET_MENU_LIST_SHOW"}
      },
      {
        path: 'menus/show/:id',
        name: 'Jadłospisy / Pokaż',
        component: Diet,
        meta: { requiresAuth: true, fullName: 'Jadłospis', role: "ROLE_AS_DIET_MENU_LIST_SHOW"}
      },
      {
        path: 'plans',
        name: 'plans',
        component: MiniCalendar,
        meta: { requiresAuth: true, fullName: 'Plany żywionych', role: "ROLE_AS_NOURISHED_PLAN_LIST_SHOW"},
        children: [
          {
            path: 'show/:id',
            name: 'Plany żywionych / Pokaż',
            component: NourishedPlanManagement,
            props: { default: true },
            meta: { requiresAuth: true, fullName: 'Plany żywionych', role: "ROLE_AS_NOURISHED_PLAN_ADD_EDIT"},
          },
        ],
      },
      {
        path: 'report_nourished',
        name: 'Raporty / Ilość żywionych',
        component: ReportNourished,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'Raport : Ilość żywionych', role: "ROLE_AS_REPORT_NOURISHED_PLAN"}
      },
      {
        path: 'report_income',
        name: 'Raporty / Miesięczna wartość usługi',
        component: ReportIncome,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'Raport : Miesięczna wartość usługi', role: "ROLE_AS_REPORT_INCOME_VALUE"}
      },
      {
        path: 'report_nutritional',
        name: 'Raporty / Wartości odżywcze',
        component: ReportNutritional,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'Raport : Wartości odżywcze', role: "ROLE_AS_REPORT_NUTRITIONAL_VALUES"}
      },
      {
        path: 'elearning',
        name: 'Pomoc / E-learning',
        component: Elearning,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'E-learning', role: "ROLE_AS_ELEARNING"}
      },
      {
        path: 'elearning/show/:id',
        name: 'Pomoc / E-learning / Pokaż',
        component: ElearningShow,
        meta: { requiresAuth: true, fullName: 'E-learning', role: "ROLE_AS_ELEARNING"}
      },
      {
        path: 'knowledge_base',
        name: 'Pomoc / Baza wiedzy',
        component: KnowledgeBase,
        props: { conf: conf },
        meta: { requiresAuth: true, fullName: 'Baza wiedzy', role: "ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW"}
      },
      {
        path: 'knowledge_base/show/:id',
        name: 'Pomoc / Baza wiedzy / Pokaż',
        component: KnowledgeBaseShow,
        meta: { requiresAuth: true, fullName: 'Baza wiedzy', role: "ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW"}
      },
      {
        path: 'tickets',
        name: 'Pomoc / Zgłoszenia',
        component: Table,
        props: { conf: confticket },
        meta: { requiresAuth: true, fullName: 'Zgłoszenia', role: "ROLE_AS_TICKET_LIST_SHOW"}
      },
      {
        path: 'tickets/add',
        name: 'Pomoc / Zgłoszenia / Dodaj',
        component: TicketAdd,
        meta: { requiresAuth: true, fullName: 'Dodawanie zgłoszenia', role: "ROLE_AS_TICKET_ADD_EDIT"}
      },
      {
        path: 'tickets/show/:id',
        name: 'Pomoc / Zgłoszenia / Pokaż',
        component: TicketShow,
        meta: { requiresAuth: true, fullName: 'Zgłoszenie', role: "ROLE_AS_TICKET_ADD_EDIT"}
      },
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: { requiresAuth: false, fullName: 'Strona logowania'},
  },
  {
    path: '/switch-user/:user_uuid/token/:user_token',
    component: SwitchUser,
    name: 'recovery_switch_user',
    meta: { requiresAuth: false, fullName: 'Przełączanie kontekstu użytkownika'},
  },
  {
    path: '/login/recovery',
    component: Container,
    name: 'recovery',
    meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła'},
    children: [
      {
        path: 'step-1',
        component: Step1,
        name: 'recovery_step_1',
        meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła' },
      },
      {
        path: 'step-2/:user_uuid/token/:user_token',
        component: Step2,
        name: 'recovery_step_2',
        meta: { requiresAuth: false, fullName: 'Odzyskiwanie hasła' },
      },
    ]
  },
  {
    path: '/404',
    component: PageNotFound,
    name: '404',
    meta: { requiresAuth: false, fullName: '404'},
  },
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('auth/checkTokenPresence'); // does not affect router access and authorization. Used to fill store's token property from localStorage

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/authenticated']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
        if(store.getters['auth/user'].roles === null) {
          store.dispatch('auth/getUserProfile', 'fromRouter')
          .then(()=>{
            checkRoles(to, next)
          })
          return
        }
        else {
          checkRoles(to, next)
        }
    }
  } else {
    next() // make sure to always call next()!
  }
});

export default router


function checkRoles(to, next){
  if(!(store.getters['auth/user'].roles.includes("ROLE_AS_ACCESS") && store.getters['auth/user'].roles.includes("ROLE_AS_NOURISHED_PLAN_LIST_SHOW"))) {
    next('/login')
    store.commit('recovery/UPDATE_ERROR', {
      status: true,
      color: '#dc3545',
      error: 'Wpisałeś błędne hasło lub email lub nie masz dostępu do aplikacji',
    }, {root: true})
    return
  }
  store.getters['auth/user'].roles.includes("ROLE_AS_ACCESS") &&
  store.getters['auth/user'].roles.includes("ROLE_AS_NOURISHED_PLAN_LIST_SHOW") &&
  store.getters['auth/user'].roles.includes(to.meta.role) ? next() : next({path: '/404', query: { redirect: to.fullPath }})
}