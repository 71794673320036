<template>

<nav class="navbar navbar-expand-lg navbar-dark py-0">
  <router-link to="/" tag="a" class="navbar-brand py-0">
    <img class='logo-nav-block__logo' src="../assets/images/logo-imfood.jpg" alt="imFood logo">
  </router-link>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <router-link to="/" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')">
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-kalendarz" />
            Kalendarz
          </a>
        </li>
      </router-link>

      <router-link to="/diets" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_DIET_MENU_LIST_SHOW')">
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-diety" />
            Jadłospisy
          </a>
        </li>
      </router-link>

      <router-link to="/plans" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')">
        <li class="nav-item" :class="[isExactActive && 'active']">
          <a :href="href" class="nav-link" @click="navigate">
            <i class="icon-plany-zywionych" />
            Plany żywionych
          </a>
        </li>
      </router-link>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" :class="[isParentHelpActive ? 'active' : '']" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fe fe-life-buoy" />
          Pomoc <i class="ml-1 fe fe-chevron-down"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <router-link to="/elearning" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_ELEARNING')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-play mr-2" />
              E-learning
            </a>
          </router-link>
          <router-link to="/knowledge_base" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-book-open mr-2" />
              Baza wiedzy
            </a>
          </router-link>
          <router-link to="/tickets" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_TICKET_LIST_SHOW')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-bookmark mr-2" />
              Zgłoszenia
            </a>
          </router-link>
        </div>
      </li>

      <li class="nav-item dropdown" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
        <a class="nav-link dropdown-toggle" :class="[isParentReportsActive ? 'active' : '']" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fe fe-database" />
          Raporty <i class="ml-1 fe fe-chevron-down"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown2">
          <router-link to="/report_nourished" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NOURISHED_PLAN')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Raport ilości żywionych
            </a>
          </router-link>
          <router-link to="/report_income" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_INCOME_VALUE')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Miesięczna wartość usługi
            </a>
          </router-link>
          <router-link to="/report_nutritional" v-slot="{ href, navigate, isExactActive }" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NUTRITIONAL_VALUES')">
            <a :href="href" class="dropdown-item" :class="[isExactActive && 'active']" @click="navigate">
              <i class="fe fe-database mr-2" />
              Raport wartości odżywczych
            </a>
          </router-link>
        </div>
      </li>
    </ul>
    <div class="ml-auto">

        <ul class="navbar-nav">
            <SelectDepartment class="order-4 order-lg-1" />
            <li class="nav-item dropdown order-2">
                <a aria-expanded="false" @click="setIncomingInfo" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdownBell" role="button" style="position: relative">
                    <i class="fe fe-bell mr-1 mr-lg-0"></i>
                    <span class="d-lg-none">Powiadomienia</span>
                    <span class="d-block bg-danger"
                      v-if="incoming_info == true"
                      style="position: absolute; z-index:1; height:10px; width:10px; top:30%; right:10%; border-radius: 10px"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBell">
                    <div class="dropdown-header text-white">
                      <template v-if="incoming_info == false">
                        Brak zmian w planach żywionych
                      </template>
                      <template v-else>
                        Uwaga! Plany żywionych zostały zaktualizowane.
                      </template>
                    </div>
                </div><!-- end dropdown-menu -->
            </li><!-- end nav-item dropdown -->


            <li class="nav-item dropdown order-3">
                <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdownProfile" role="button">
                    <i class="icon-user mr-1 mr-lg-0"></i>
                    <span class="d-lg-none">Profil</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <div class="dropdown-header text-white">
                        {{ $store.getters['auth/user'].username }}
                    </div>
                    <a class="dropdown-item" @click="logOut" href="">
                        <i class="icon-wyloguj pr-2"></i>Wyloguj
                    </a>
                </div><!-- end dropdown-menu -->
            </li><!-- end nav-item dropdown -->

        </ul><!-- end navbar-nav -->


      <!-- <div class="user-nav-manipulation">
        <div class="dropdown dropleft" style='display: flex;'>
          <button class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" :aria-haspopup="true" aria-expanded="false" @click='setIncomingInfo'>
            <a href="#" class='user-block__bell'>
              <i class='fe fe-bell' :class='{"red-bell" : incoming_info === true}' />
              <span class='alert-circle' :class='{"alert-circle--invisible":  incoming_info === false}'> ! </span>
            </a>

          </button>
          <div class="dropdown-menu dm nav-dropdown" :class='{"dropdown--zero-padding": incoming_info === true}' aria-labelledby="dropdownMenuButton">
            <div v-if='incoming_info === false'>
              <p>Brak zmian</p>
            </div>
            <div v-else>
              <p>Uwaga! Plany żywionych zostały zaktualizowane.</p>
            </div>
          </div>
        </div>

        <div class="pipe"></div>
        <a @click='logOut' href="" class='user-block__user'>
          <i class='fe fe-log-out' />
        </a>
      </div> -->


    </div>

  </div>
</nav>

  <!-- <nav class="top-header">
    <vue-progress-bar></vue-progress-bar>
    <div class="logo-nav-block">
      <router-link to='/' tag='a'>
        <img class='logo-nav-block__logo' src="../assets/images/logo-imfood.jpg" alt="imFood logo">
      </router-link>

      <ul class='main-navigation'>
        <li class="main-navigation__element" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')">
          <router-link exact active-class="main-navigation__element--active" class="main-navigation__link" to='/'>
            <i class="icon-kalendarz" />
            Kalendarz
          </router-link>
        </li>
        <li class="main-navigation__element" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_DIET_MENU_LIST_SHOW')">
          <router-link active-class="main-navigation__element--active" class="main-navigation__link" to='/diets'>
            <i class="icon-diety" />
            Jadłospisy
          </router-link>
        </li>
        <li class="main-navigation__element" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_NOURISHED_PLAN_LIST_SHOW')">
          <router-link active-class="main-navigation__element--active" class="main-navigation__link " to='/plans'>
            <i class="icon-plany-zywionych" />
            Plany żywionych
          </router-link>
        </li>
        <li class="main-navigation__element dropdown">
          <a href="#" class="main-navigation__link dropdown-toggle text-white" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fe fe-life-buoy" />
            Pomoc
          </a>
          <div class="dropdown-menu text-white py-0" aria-labelledby="dropdownMenuButton2" style="margin-top:50px">
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/elearning' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_ELEARNING')">
              <i class="fe fe-play mr-2" />
              E-learning
            </router-link>
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/knowledge_base' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_KNOWLEDGE_BASE_LIST_SHOW')">
              <i class="fe fe-book-open mr-2" />
              Baza wiedzy
            </router-link>
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/tickets' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_TICKET_LIST_SHOW')">
              <i class="fe fe-bookmark mr-2" />
              Zgłoszenia
            </router-link>
          </div>
        </li>
        <li class="main-navigation__element dropdown" v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_SHOW_LIST')">
          <a href="#" class="main-navigation__link dropdown-toggle text-white" id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fe fe-database" />
            Raporty
          </a>
          <div class="dropdown-menu text-white py-0" aria-labelledby="dropdownMenuButton3" style="margin-top:50px">
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/report_nourished' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NOURISHED_PLAN')">
              <i class="fe fe-database mr-2" />
              Raport ilości żywionych
            </router-link>
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/report_income' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_INCOME_VALUE')">
              <i class="fe fe-database mr-2" />
              Miesięczna wartość usługi
            </router-link>
            <router-link tag='a' class="dropdown-item main-navigation__link" to='/report_nutritional' v-if="$store.getters['auth/user'].roles.includes('ROLE_AS_REPORT_NUTRITIONAL_VALUES')">
              <i class="fe fe-database mr-2" />
              Raport wartości odżywczych
            </router-link>
          </div>
        </li>
      </ul>
    </div>
    <div class="user-block">
      <SelectDepartment />
      <div class="user-nav-manipulation">
        <div class="dropdown dropleft" style='display: flex;'>
          <button class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" :aria-haspopup="true" aria-expanded="false" @click='setIncomingInfo'>
            <a href="#" class='user-block__bell'>
              <i class='fe fe-bell' :class='{"red-bell" : incoming_info === true}' />
              <span class='alert-circle' :class='{"alert-circle--invisible":  incoming_info === false}'> ! </span>
            </a>

          </button>
          <div class="dropdown-menu dm nav-dropdown" :class='{"dropdown--zero-padding": incoming_info === true}' aria-labelledby="dropdownMenuButton">
            <div v-if='incoming_info === false'>
              <p>Brak zmian</p>
            </div>
            <div v-else>
              <p>Uwaga! Plany żywionych zostały zaktualizowane.</p>
            </div>
          </div>
        </div>

        <div class="pipe"></div>
        <a @click='logOut' href="" class='user-block__user'>
          <i class='fe fe-log-out' />
        </a>
      </div>
    </div>

  </nav> -->
</template>

<script>
import SelectDepartment from "../components/SelectDepartment.vue";
import { mapState } from "vuex";
// import {routes} from '@/router/index';
export default {
  name: "navigation",
  components: {
    SelectDepartment,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState("plans", ["incoming_info"]),
    isParentHelpActive() {
      if (
        this.$route.path.includes("elearning") ||
        this.$route.path.includes("knowledge_base") ||
        this.$route.path.includes("tickets")
        ) {
        return true
      }
      return false
    },
    isParentReportsActive() {
      if (
        this.$route.path.includes("report_nourished") ||
        this.$route.path.includes("report_income") ||
        this.$route.path.includes("report_nutritional")
        ) {
        return true
      }
      return false
    },

  },
  methods: {
    logOut() {
      localStorage.removeItem("token");
    },
    setIncomingInfo() {
      setTimeout(() => {
        this.$store.commit("plans/SET_INCOMING_INFO", false);
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
// #dropdownMenuButton {
//   &:after,
//   &:before {
//     display: none;
//   }
// }
// .dropdown {
//   display: flex;
// }
// .dropdown-menu {
//   transform: translate3d(-230px, 35px, 0px) !important;
//   width: 250px;
//   border-radius: 2px;
//   color: black !important;
//   padding-bottom: 5px !important;
//   & p {
//     padding: 5px 15px;
//     margin: 0;
//     text-align: center;
//   }
//   &.dm:before {
//     position: absolute;
//     width: 10px;
//     height: 10px;
//     transform: rotate(45deg);
//     background-color: white;
//     content: "";
//     top: -5px;
//     right: 7px;
//   }
// }
// .alert-circle {
//   font-size: 35px;
//   color: red;
//   position: absolute;
//   right: -8px;
//   &--invisible {
//     visibility: hidden;
//   }
// }
// .red-bell {
//   color: red;
// }
// .dropdown--zero-padding {
//   padding-bottom: 0 !important;
// }

// .loader-select {
//   border-width: 3px !important;
//   border-top: 3px solid #3498db !important;
//   width: 20px !important;
//   height: 20px !important;
// }
</style>
